<template>
	<div class="bookingFormHeader puiformheader">
		<v-row>
			<v-col cols="4">
				<pui-field :label="$t('header.booking.portname')" :value="getPortnameValue"></pui-field>
			</v-col>
			<v-col cols="4">
				<pui-field :label="$t('header.booking.berthname')" :value="getBerthnameValue"></pui-field>
			</v-col>
			<v-col cols="4">
				<pui-field :label="$t('header.booking.shipname')" :value="getShipnameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'bookingFormHeader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'booking'
		};
	},
	computed: {
		getPortnameValue() {
			return this.model && this.model.portname ? this.model.portname : '-';
		},
		getBerthnameValue() {
			return this.model && this.model.berthname ? this.model.berthname : '-';
		},
		getShipnameValue() {
			return this.model && this.model.shipname ? this.model.shipname : '-';
		}
	}
};
</script>
